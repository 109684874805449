import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { FlexContainer, SiteContent } from '../components/styles'
import {css} from '@emotion/core'

const SEITE = gql`
{
  seiten(where: {seite_in: Philosophie}) {
    titel1
    titel2 {
      html
    }
    textblock1 {
      html
    }
  }
}

`

const Philosophie = () => {
    const {data} = useQuery(SEITE)

    return (
        <Layout layoutImage="philosophie">
            <SiteContent>
            <FlexContainer direction="column">
            <SEO title="Philosophie" />
                {data && data.seiten[0].title1 && <h3>{data.seiten[0].title1}</h3>}
                {data && data.seiten[0].titel2.html && <div dangerouslySetInnerHTML={{__html: data.seiten[0].titel2.html}} css={css`width: 93%;`} /> }
                {data && data.seiten[0].textblock1.html && <div dangerouslySetInnerHTML={{__html: data.seiten[0].textblock1.html}} css={{width: "100%"}} /> }
            
            </FlexContainer>

            </SiteContent>


        </Layout>
       

    )
}

export default Philosophie